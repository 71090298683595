import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/front/home',  // 重定向到主页
    children: [
      { path: '403', name: 'NoAuth', meta: { name: '无权限' }, component: () => import('../views/manager/403') },
      { path: 'home', name: 'Home', meta: { name: '系统首页' }, component: () => import('../views/manager/Home') },
      { path: 'adminPerson', name: 'AdminPerson', meta: { name: '个人信息' }, component: () => import('../views/manager/AdminPerson') },
      { path: 'password', name: 'Password', meta: { name: '修改密码' }, component: () => import('../views/manager/Password') },
      { path: 'notice', name: 'Notice', meta: { name: '公告信息' }, component: () => import('../views/manager/Notice') },
      { path: 'user', name: 'User', meta: { name: '用户信息' }, component: () => import('../views/manager/User') },
      { path: 'type', name: 'Type', meta: { name: '分类信息' }, component: () => import('../views/manager/Type') },
      { path: 'news', name: 'News', meta: { name: '资讯信息' }, component: () => import('../views/manager/News') },
      { path: 'product', name: 'Product', meta: { name: '产品管理' }, component: () => import('../views/manager/Product') },
      { path: 'advertisement', name: 'Advertisement', meta: { name: '广告管理' }, component: () => import('../views/manager/Advertisement') },
      { path: 'pointsLog', name: 'PointsLog', meta: { name: '积分记录' }, component: () => import('../views/manager/PointsLog') },
      { path: 'serve', name: 'Serve', meta: { name: '赛事服务' }, component: () => import('../views/manager/Serve') },
      { path: 'reserve', name: 'Reserve', meta: { name: '服务预约' }, component: () => import('../views/manager/Reserve') },
    ]
  },
  {
    path: '/front',
    name: 'Front',
    component: () => import('../views/Front.vue'),
    children: [
      { path: 'home', name: 'FHome', meta: { name: '系统首页' }, component: () => import('../views/front/Home') },
      { path: 'person', name: 'FPerson', meta: { name: '个人信息' }, component: () => import('../views/front/Person') },
      { path: 'newsDetail', name: 'FNewsDetail', component: () => import('../views/front/NewsDetail') },
      { path: 'productDetail', name: 'FProductDetail', component: () => import('../views/front/ProductDetail') },
      { path: 'ruleDetail', name: 'FRuleDetail', component: () => import('../views/front/RuleDetail') },
      { path: 'product', name: 'FProduct', component: () => import('../views/front/Product') },
      { path: 'serve', name: 'FServe', component: () => import('../views/front/Serve') },
      { path: 'reserve', name: 'FReserve', component: () => import('../views/front/Reserve') },
      { path: 'advertisement', name: 'Advertisement', component: () => import('../views/front/Advertisement') },
      { path: 'advertisementDetail', name: 'AdvertisementDetail', component: () => import('../views/front/AdvertisementDetail') },
      { path: 'myAI', name: 'myAI', component: () => import('../views/front/myAI') },
    ]
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('../views/mobile/Mobile.vue'),
    children: [
      { path: 'home', name: 'MHome', meta: { name: '系统首页' }, component: () => import('../views/mobile/Home') },
      { path: 'person', name: 'FPerson', meta: { name: '个人信息' }, component: () => import('../views/mobile/Person') },
      { path: 'newsDetail', name: 'FNewsDetail', component: () => import('../views/mobile/NewsDetail') },
      { path: 'productDetail', name: 'FProductDetail', component: () => import('../views/mobile/ProductDetail') },
      { path: 'ruleDetail', name: 'FRuleDetail', component: () => import('../views/mobile/RuleDetail') },
      { path: 'product', name: 'FProduct', component: () => import('../views/mobile/Product') },
      { path: 'serve', name: 'FServe', component: () => import('../views/mobile/Serve') },
      { path: 'reserve', name: 'FReserve', component: () => import('../views/mobile/Reserve') },
      { path: 'advertisement', name: 'Advertisement', component: () => import('../views/mobile/Advertisement') },
      { path: 'advertisementDetail', name: 'AdvertisementDetail', component: () => import('../views/mobile/AdvertisementDetail') },
      { path: 'myAI', name: 'myAI', component: () => import('../views/mobile/myAI') },
    ]
  },
  { path: '/login', name: 'Login', meta: { name: '登录' }, component: () => import('../views/Login.vue') },
  { path: '/register', name: 'Register', meta: { name: '注册' }, component: () => import('../views/Register.vue') },
  { path: '*', name: 'NotFound', meta: { name: '无法访问' }, component: () => import('../views/404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 注：不需要前台的项目，可以注释掉该路由守卫
// 路由守卫
// router.beforeEach((to ,from, next) => {
//   let user = JSON.parse(localStorage.getItem("xm-user") || '{}');
//   if (to.path === '/') {
//     if (user.role) {
//       if (user.role === 'USER') {
//         next('/front/home')
//       } else {
//         next('/home')
//       }
//     } else {
//       next('/login')
//     }
//   } else {
//     next()
//   }
// })

export default router
